<template>
  <div>
    <b-container class="container pt-3 pt-md-4" style="min-height:500px;background-color:#fff">
      <b-row class="mb-4">
        <b-col lg="6" sm="12" class="d-flex justify-content-center p-2">
          <b-row v-if="!loading && school" class="border rounded p-2 w-100" style="min-height:218px">
            <b-col lg="5" sm="12" class="d-flex justify-content-center">
              <div>
                <b-skeleton  v-if="loading && !school" type="avatar" height="180px" width="180px"></b-skeleton>
                <router-link  v-else :to="{ name: 'schoolProfile'}" ><b-avatar v-if="school" variant="light-gray" :src="school.doc_logo ? school.doc_logo : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'" size="12.5rem" class="ml-3"></b-avatar></router-link>
              </div>
            </b-col>
            <b-col lg="7" sm="12" class="align-self-center text-center text-lg-left">
              <div v-if="loading && !school">
                <b-skeleton></b-skeleton>
                <b-skeleton></b-skeleton>
                <b-skeleton></b-skeleton>
              </div>
              <div v-else class="mt-3 mt-lg-0">
                <h2 v-if="school" class="profile-name text-primary-dark text-capitalize"> {{ school.school_name }} </h2>
                <h5 v-if="school" class="text-gray-light"> {{ school.region_name }} </h5>
              </div>
            </b-col>
          </b-row>
          <b-row class="border w-100 rounded p-2 d-flex justify-content-center align-items-center" style="min-height:218px" v-if="!loading && !school">
            <router-link :to="{ name: 'schoolProfile'}"><h2 class="profile-name text-primary-dark text-capitalize"><b-icon icon="person-lines-fill" class="d-inline"></b-icon> {{ $t('School Registration')}} </h2></router-link>
          </b-row>
        </b-col>
        <b-col lg="6" sm="12" class="align-self-center text-center text-lg-left p-2">
          <b-row v-if="!loading && teacher"  class="border rounded p-2 w-100" style="min-height:218px">
            <b-col lg="5" sm="12" class="d-flex justify-content-center">
              <div>
                <b-skeleton  v-if="loading && !teacher" type="avatar" height="180px" width="180px"></b-skeleton>
                <router-link  v-else :to="{ name: 'teacherProfile'}"><b-avatar  v-if="teacher" variant="light-gray" :src="teacher.doc_avatar ? teacher.doc_avatar : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'" size="12.5rem" class="ml-3"></b-avatar></router-link>
              </div>
            </b-col>
            <b-col lg="7" sm="12" class="align-self-center text-center text-lg-left">
              <div v-if="loading && !teacher">
                <b-skeleton></b-skeleton>
                <b-skeleton></b-skeleton>
                <b-skeleton></b-skeleton>
              </div>
              <div v-else class="mt-3 mt-lg-0">
                <h2 v-if="teacher" class="profile-name text-primary-dark text-capitalize"> {{ teacher.name }} </h2>
                <h5 v-if="teacher" class="text-gray-light"> {{ teacher.region_name }} </h5>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!loading && !teacher" class="border rounded p-2 d-flex justify-content-center align-items-center" style="min-height:218px">
            <router-link :to="{ name: 'teacherProfile'}"><h2 class="profile-name text-primary-dark text-capitalize"><b-icon icon="person-lines-fill" class="d-inline"></b-icon> {{ $t('Teacher Registration')}} </h2></router-link>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <h3 class="d-block">{{ $t('Basic info') }}</h3><br>
        </b-col>
        <b-col lg="3" sm="12" class="d-flex justify-content-center">
          <div>
            <b-skeleton  v-if="loading == true" type="avatar" height="210px" width="210px"></b-skeleton>
            <b-avatar  v-if="loading == false" variant="light-gray" :src="userProfile.profile !== undefined ? userProfile.profile.avatar : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'" size="12.5rem" class="ml-3"></b-avatar>
            <div class="btn-img-edit-profile">
              <b-icon icon="camera" id="pick-avatar" class="upload-avatar rounded-circle btn-danger-light p-2" variant="light"></b-icon>
            </div>
          </div>
        </b-col>
        <b-col lg="9" sm="12" class="align-self-center text-center text-lg-left">
          <div class="mt-3 mt-lg-0">
            <h2 class="profile-name text-primary-dark text-capitalize"> {{ (userInfo.firstName ? userInfo.firstName + ' ' +  userInfo.lastName : userInfo.username) }} </h2>
            <h5 v-if="userProfile" class="text-gray-light"> {{ (userProfile.profile ? userProfile.profile.regionName : '-') }} </h5>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4 mb-4">
        <b-col lg="12" sm="12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
              :name="$t('Name')"
              rules="required"
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-name" :label="$t('Name')+'*'">
              <b-form-input id="input-username" type="text" class="input-auth" v-model="form.first_name" :placeholder="$t('Name')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Lastname')"
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-name" :label="$t('Lastname')">
              <b-form-input id="input-username" type="text" class="input-auth" v-model="form.last_name" :placeholder="$t('Lastname')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Email')"
              rules="required"
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-email" :label="$t('Email')+'*'">
              <b-form-input id="input-email" type="email" class="input-auth" v-model="form.email" :placeholder="$t('Email')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Phone number')"
              rules="numeric"
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-phonenumber" :label="$t('Phone number')">
              <b-form-input id="input-phonenumber" type="text" class="input-auth" v-model="form.profile.phone_number" :placeholder="$t('Phone number')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Location')"
              rules=""
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pr-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-Location" :label="$t('Location')">
                <v-select ref="search-destination-school" class="form-v-selects" :placeholder="$t('Province or States')" label="text" :value="selectedRegion"  v-model="selectedRegion" :filterable="false" :options="regions" @search="searchRegion">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search 11"
                      v-bind="attributes"
                      v-on="events"
                      @click="setReadOnly"
                    />
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      {{ $t('No results found for') }} <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else><small>{{ $t('Typing to search for a Province or States') }}</small></em>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.text }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.text }}
                    </div>
                  </template>
                </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
              :name="$t('Website')"
              rules=""
              autocomplete="off"
              v-slot="{errors}"
            >
            <b-form-group class="d-inline px-0 pl-lg-2 col-sm-12 col-lg-6 font-weight-bold text-primary-dark" for="input-website" :label="$t('Website')">
              <b-form-input id="input-website" type="text" class="input-auth" v-model="form.profile.web_site_url" :placeholder="$t('Website')"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <h3 class="d-block mt-4">{{ $t('About me') }}</h3><br>
          <b-form-textarea
            id="textarea"
            v-model="form.profile.detail"
            :placeholder="$t('Let me know about you')"
            rows="10"
            max-rows="10"
          ></b-form-textarea>
          <div class="row">
            <b-col sm="12" lg="12" class="d-flex justify-content-center">
              <b-button pill @click="setForm" class="mt-2  px-5 my-4 btn-light font-weight-bold d-inline">{{ $t('Cancel') }}</b-button>
              <b-button pill type="submit" :disabled="isLoading" class="d-inline ml-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>{{ $t('Save') }}</b-button>
            </b-col>
          </div>
        </b-form>
        </ValidationObserver>
        </b-col>
        <avatar-cropper
          trigger="#pick-avatar"
          :upload-url="urlUpload"
          @uploading="handleUploading"
          @completed="handleCompleted"
          @error="handlerError"
          :output-options="{ width:512, height:512}"
          :output-quality="0.6"
          :upload-headers="uploadHeader"
          :labels="{ submit: $t('Upload'), cancel: $t('Cancel')}"
          mimes="image/png, image/gif, image/jpeg"
          upload-form-name="avatar"
          />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AvatarCropper from 'vue-avatar-cropper'
import Api from '../api/api'
import 'vue-select/dist/vue-select.css'
import UpcomingEvents from '../components/UpcomingEvents.vue';

export default {
  name: 'UserProfile',
  title: ' | User Profile',
  components: {
    AvatarCropper,
    UpcomingEvents,
  },
  data () {
    return {
      urlUpload: process.env.VUE_APP_API_ENDPOINT + '/api/user-profile/avatar/',
      uploadHeader: { Authorization: 'Bearer ' + localStorage.getItem('accessToken') },
      school: null,
      teacher: null,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        profile: {
          phone_number: null,
          detail: null,
          region: null,
          web_site_url: null
        }
      },
      userAvatar: undefined,
      isLoading: false,
      selectedRegion: {},
      searchText: '',
      regions: [],
      loading: true
    }
  },
  watch: {
    selectedRegion: function (val) {
      this.form.profile.region = val.value
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.userInfo
    },
    userProfile () {
      return this.$store.getters.userProfile
    },
    schoolId () {
      return this.$store.getters.schoolId
    },
    teacherId () {
      return this.$store.getters.teacherId
    }
  },
  async mounted () {
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.$store.dispatch('userProfile').then(async (response) => {
      await this.setForm()
      await this.getSchool()
      await this.getTeacher()
      this.loading = false
    })
  },
  methods: {
    setReadOnly (el) {
      setTimeout(() => {
        el.target.removeAttribute('readonly')
      }, 80)
    },
    setForm () {
      this.form.first_name = this.userProfile.firstName
      this.form.last_name = this.userProfile.lastName
      this.form.email = this.userProfile.email
      this.form.profile.detail = this.userProfile.profile.detail
      this.form.profile.region = this.userProfile.profile.region
      if (this.form.profile.region) {
        this.regions = [{ value: this.userProfile.profile.region, text: this.userProfile.profile.regionName }]
        this.selectedRegion = { value: this.userProfile.profile.region, text: this.userProfile.profile.regionName }
      }
      this.form.profile.phone_number = this.userProfile.profile.phoneNumber
      this.form.profile.web_site_url = this.userProfile.profile.webSiteUrl
    },
    onSubmit () {
      this.$refs.form.validate().then(result => {
        var tempForm = this.form
        if (this.form.email === this.userProfile.email) {
          delete tempForm.email
        }
        if (result) {
          this.$store.dispatch('updateUserProfile', tempForm)
            .then((response) => {
              this.isLoading = false
              this.$notify({
                group: 'success',
                title: this.$t('Update user profile'),
                text: this.$t('Successfully saved data')
              })
              this.$store.dispatch('userInfo')
              this.$store.dispatch('userProfile')
              this.$store.dispatch('userProfile').then(
                this.setForm()
              )
            })
            .catch((error) => {
              this.isLoading = false
              const responseError = error.response
              let textError = ''
              if (responseError) {
                textError = Object.values(responseError.data).join()
              } else {
                textError = this.$t('Unsuccessfully saved data')
              }
              this.$notify({
                group: 'error',
                title: this.$t('Update user profile'),
                text: textError
              })
            })
        }
      })
    },
    handleUploading (form, xhr) {
      form.append('via', 'request')
    },
    handleCompleted (response, form, xhr) {
      if (response.avatar) {
        this.$store.dispatch('userProfile')
        this.$notify({
          group: 'success',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar successful')
        })
      } else {
        this.$notify({
          group: 'error',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar unsuccessful')
        })
      }
    },
    handlerError (message, type, xhr) {
      if (type === 'upload') {
        this.$notify({
          group: 'error',
          title: this.$t('Upload avatar'),
          text: this.$t('Upload avatar unsuccessful')
        })
      }
    },
    searchRegion (searchText) {
      this.searchText = searchText
      if (this.searchText) {
        this.regions = []
        Api.searchRegion({ q: this.searchText }).then(response => {
          if (response.data.results) {
            response.data.results.forEach(region => {
              const url = region.url.split('/')
              this.regions.push({ value: url[url.length - 2], text: region.display_name })
            })
          }
        })
      }
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    toggleNavbarNavClass (addRemoveClass, className) {
      const nav = document.querySelector('.navbar-nav')
      if (addRemoveClass === 'addClass') {
        nav.classList.add(className)
      } else {
        nav.classList.remove(className)
      }
    },
    async getSchool () {
      this.$store.dispatch('getMySchool', { user: this.userProfile.id }).then(response => {
        if (response.data.results) {
          if (response.data.results.length > 0) {
            this.school = response.data.results[0]
          }
        }
      })
    },
    async getTeacher () {
      this.$store.dispatch('getTeacher', { user: this.userProfile.id }).then(response => {
        if (response.data.results) {
          if (response.data.results.length > 0) {
            this.teacher = response.data.results[0]
          }
        }
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style>
  .btn-img-edit-profile {
    font-size: 2.5rem;
    top: 120px;
    float: right;
    position: absolute;
    margin-left: 180px;
  }
  .btn-img-edit-profile:hover {
    cursor: pointer;
  }
  .avatar-cropper-btn:hover {
    background-color: #3B6098 !important;
  }
  .v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
  }

  .v-select .dropdown li:last-child {
    border-bottom: none;
  }

  .v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
  }

  .v-select .dropdown-menu .active > a {
    color: #fff;
  }
  .vs__dropdown-menu > li {
    padding: 5px !important;
    padding-left: 7px !important;
    border-bottom: 1px solid #f6f8fa;
  }
</style>
